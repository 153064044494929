<template>
  <div>
    <v-container class="px-10">
      <TheTitle text="Relatório Geral LGPD" icon="mdi-chart-tree">
        <v-row align="center" justify="center">
          <v-col class="text-right">
            <v-btn
              @click="filter.text = !filter.text"
              :text="!filter.text"
              color="white"
              >Incluir Textos</v-btn
            >
            <v-btn
              depressed
              dark
              color="amber darken-4"
              :loading="loading"
              class="ml-5 pr-7"
              @click="exportHTML()"
              ><v-icon class="mx-2">mdi-printer</v-icon> Imprimir</v-btn
            >
          </v-col>
        </v-row>
      </TheTitle>
    </v-container>

    <v-container>
      <v-card class="py-5 px-10 report" id="printArea" ref="printable">
        <div class="text-right notprint" v-if="printer">
          <v-btn
            text
            @click="foldAll()"
            outlined
            class="ml-2 notprint"
            v-if="!fold"
            >Expandir Todos</v-btn
          >
          <v-btn
            text
            @click="unfoldAll()"
            outlined
            class="ml-2 notprint"
            v-if="fold"
            >Recolher Todos</v-btn
          >
        </div>
        <v-divider class="my-2 notprint"></v-divider>
        <div v-if="filter.text" class="my-5">
          <div v-for="(item, i) in text" :key="i">
            <v-row class="my-5" align="center" justify="center">
              <v-col cols="10">
                <h1 style="font-size: 1.5em;">
                  {{ romanize(i + 1) }}. {{ item.name }}
                </h1>
              </v-col>
              <v-col class="text-right notprint" v-if="printer">
                <v-icon
                  @click="expand(item)"
                  class="mr-2 notprint"
                  v-if="item.expanded"
                >
                  mdi-arrow-collapse-vertical
                </v-icon>
                <v-icon
                  @click="expand(item)"
                  class="mr-2 notprint"
                  v-if="!item.expanded"
                >
                  mdi-arrow-expand-vertical
                </v-icon>
              </v-col>
            </v-row>
            <div v-html="item.content" v-if="item.expanded"></div>
            <v-divider class="mb-5 notprint"></v-divider>
          </div>
        </div>

        <div v-for="(item, i) in objects" :key="i">
          <v-row class="my-5" align="center" justify="center">
            <v-col cols="10">
              <h1 style="font-size: 1.5em;">
                {{ romanize(i + 1) }}. Setor {{ item.sectorName }}
                <span
                  v-if="
                    item.activityName && item.sectorName != item.activityName
                  "
                  >- {{ item.activityName }}</span
                >
              </h1>
            </v-col>
            <v-col class="text-right notprint" v-if="printer">
              <v-icon
                @click="expand(item)"
                class="mr-2 notprint"
                v-if="item.expanded"
              >
                mdi-arrow-collapse-vertical
              </v-icon>
              <v-icon
                @click="expand(item)"
                class="mr-2 notprint"
                v-if="!item.expanded"
              >
                mdi-arrow-expand-vertical
              </v-icon>
            </v-col>
          </v-row>

          <div v-if="item.list && item.expanded">
            <div v-html="item.description"></div>
            <TheReportDetail :list="item.list.collect" :pos="1" />
            <TheReportDetail :list="item.list.use" :pos="2" />
            <TheReportDetail :list="item.list.share" :pos="3" />
            <TheReportDetail :list="item.list.store" :pos="4" />
            <TheReportDetail :list="item.list.discard" :pos="5" />
            <TheVulnerabilityDetail :list="item.vulnerability" :pos="6" />
            <ThePlanDetail :list="item.plan" :pos="7" />
          </div>

          <v-divider
            class="my-5 notprint"
            v-if="i < objects.length"
          ></v-divider>
        </div>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import { baseApiUrl, showError } from "@/global";
import TheReportDetail from "@/components/TheReportDetail";
import ThePlanDetail from "@/components/ThePlanDetail";
import TheVulnerabilityDetail from "@/components/TheVulnerabilityDetail";
import TheTitle from "@/components/TheTitle";

export default {
  components: {
    TheReportDetail,
    ThePlanDetail,
    TheVulnerabilityDetail,
    TheTitle
  },
  data: () => ({
    loading: false,
    objects: [],
    legal: [
      { text: "Consentimento do titular", value: "1" },
      { text: "Cumprimento de obrigação legal ou regulatória", value: "2" },
      {
        text: "Execução, pela administração pública, de políticas públicas",
        value: "3"
      },
      { text: "Realização de estudos por órgão de pesquisa", value: "4" },
      { text: "Execução de contrato", value: "5" },
      { text: "Diligências pré-contratuais", value: "6" },
      { text: "Exercício regular de direitos", value: "7" },
      { text: "Proteção da vida e da incolumidade física", value: "8" },
      { text: "Tutela da saúde", value: "9" },
      { text: "Legítimo Interesse", value: "10" },
      { text: "Proteção de crédito", value: "11" },
      { text: "Prevenção à fraude em sistemas eletrônicos", value: "12" },
      { text: "Segurança do titular em sistemas eletrônicos", value: "13" },
      { text: "Não atendida", value: "14" }
    ],
    type: [
      { text: "Coleta", value: "1" },
      { text: "Uso/Finalidade", value: "2" },
      { text: "Compartilhamento", value: "3" },
      { text: "Retenção/Armazenamento", value: "4" },
      { text: "Descarte", value: "5" },
      { text: "Vulnerabilidades", value: "6" },
      { text: "Plano de Ação", value: "7" }
    ],
    text: [],
    filter: {
      text: false
    },
    printer: true,
    fold: true
  }),
  methods: {
    expand(item) {
      item.expanded = !item.expanded;
    },
    loadData() {
      const url = `${baseApiUrl}/report/general`;
      this.$store.dispatch("setLoadingInfo", true);
      axios
        .get(url)
        .then(res => {
          this.objects = res.data;
          this.$store.dispatch("setLoadingInfo", false);
        })
        .catch(showError);
    },
    loadText() {
      const url = `${baseApiUrl}/text`;
      axios
        .get(url)
        .then(res => {
          this.text = res.data;
        })
        .catch(showError);
    },
    getType(id) {
      for (let i = 0; i < this.type.length; i++) {
        const element = this.type[i];
        if (element.value === id) {
          return element.text;
        }
      }
    },
    getLegal(id) {
      for (let i = 0; i < this.legal.length; i++) {
        const element = this.legal[i];
        if (element.value === id) {
          return element.text;
        }
      }
    },
    romanize(num) {
      if (isNaN(num)) return NaN;
      var digits = String(+num).split(""),
        key = [
          "",
          "C",
          "CC",
          "CCC",
          "CD",
          "D",
          "DC",
          "DCC",
          "DCCC",
          "CM",
          "",
          "X",
          "XX",
          "XXX",
          "XL",
          "L",
          "LX",
          "LXX",
          "LXXX",
          "XC",
          "",
          "I",
          "II",
          "III",
          "IV",
          "V",
          "VI",
          "VII",
          "VIII",
          "IX"
        ],
        roman = "",
        i = 3;
      while (i--) roman = (key[+digits.pop() + i * 10] || "") + roman;
      return Array(+digits.join("") + 1).join("M") + roman;
    },
    async print() {
      const url = `${baseApiUrl}/export/report`;
      this.filter.text = true;
      await this.foldAll();
      const content = this.$refs.printable.$el.innerHTML;
      this.loading = true;
      axios
        .post(url, { content }, { responseType: "arraybuffer" })
        .then(response => {
          const type = response.headers["content-type"];
          const blob = new Blob([response.data], {
            type: type,
            encoding: "UTF-8"
          });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "Relatório.pdf";
          link.click();
          this.loading = false;
        });
    },
    exportHTML() {
      this.printer = false;
      const vm = this.$refs.printable.$el.innerHTML;
      var styleSheetList = document.getElementsByTagName("style");
      let style = "<style>";
      for (let i = 0; i < styleSheetList.length; i++) {
        const element = styleSheetList[i];
        style += element.innerHTML;
      }
      style += "</style>";

      const word = `<html xmlns:o='urn:schemas-microsoft-com:office:office xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML to Word Document with JavaScript</title>${style}</head><body><div class='v-application'>${vm}</div></body></html>`;

      var source =
        "data:application/vnd.ms-word;charset=utf-8," +
        encodeURIComponent(word);

      var fileDownload = document.createElement("a");
      document.body.appendChild(fileDownload);
      fileDownload.href = source;
      fileDownload.download = "Relatório Geral LGPD.doc";
      fileDownload.click();
      this.printer = true;
      // document.body.removeChild(fileDownload)
    },
    async foldAll() {
      for (let i = 0; i < this.objects.length; i++) {
        this.objects[i].expanded = true;
      }
      for (let i = 0; i < this.text.length; i++) {
        this.text[i].expanded = true;
      }
      this.fold = true;
    },
    unfoldAll() {
      for (let i = 0; i < this.objects.length; i++) {
        this.objects[i].expanded = false;
      }
      for (let i = 0; i < this.text.length; i++) {
        this.text[i].expanded = false;
      }
      this.fold = false;
    }
  },
  mounted() {
    this.loadText();
    this.loadData();
  }
};
</script>

<style>
@media print {
  .notprint {
    display: none;
  }
}

.report {
  line-height: 150%;
  text-align: justify;
}
</style>
