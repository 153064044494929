<template>
  <div>
    <h2 style="font-size: 1.3em" v-if="list[0] && list[0].type" class="mb-5">
      {{ pos }}. Plano de Ação
    </h2>
    <div v-for="(subItem, j) in list" :key="j" class="report-detail mb-5">
      <p>
        <b>{{ pos }}. {{ j + 1 }}. {{ subItem.name }} </b>
      </p>

      <b v-if="subItem.description">Descrição:</b>
      <p v-if="subItem.description" v-html="removeP(subItem.description)"></p>
      <p v-if="subItem.type"><b>Tipo:</b> {{ getPlan(subItem.type) }}</p>
      <p v-if="subItem.validate">
        <b>Validade:</b> {{ subItem.validate | formatDate }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: Array,
    pos: { type: Number, default: 1 }
  },
  data: () => ({
    type: [{ text: "Plano de Ação", value: "0" }]
  }),
  methods: {
    removeP(text) {
      return text.replaceAll("<p></p>", "");
    },

    getType(id) {
      for (let i = 0; i < this.type.length; i++) {
        const element = this.type[i];
        if (element.value === id) {
          return element.text;
        }
      }
    },

    getPlan(plan) {
      if (!plan) {
        return "Não Informado";
      } else if (plan == 0) {
        return "Geral";
      } else if (plan == 1) {
        return "Específico";
      }
    }
  }
};
</script>

<style>
.remove-p p:first-child {
  display: inline;
}

.remove-p p {
  margin: 0px;
}
</style>
