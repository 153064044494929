<template>
  <div>
    <h2 style="font-size: 1.3em" v-if="list[0]" class="mb-5">
      {{ pos }}. Vulnerabilidades
    </h2>
    <div v-for="(subItem, j) in list" :key="j" class="report-detail mb-10">
      <p>
        <b>{{ pos }}. {{ j + 1 }}. {{ subItem.name }} </b>
      </p>
      <p>
        <b>Descrição: </b>
        <span
          v-html="removeP(subItem.description) || 'Não informado'"
          class="my-5 remove-p"
        ></span>
      </p>
      <p><b>Probabilidade:</b> {{ getProbability(subItem.idProbability) }}</p>
      <p><b>Risco:</b> {{ getRisk(subItem.risk) }}</p>
      <p><b>Impacto:</b> {{ getImpact(subItem.idImpact) }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: Array,
    pos: { type: Number, default: 1 }
  },

  data: () => ({
    type: [{ text: "Vulnerabilidades", value: "0" }]
  }),

  methods: {
    removeP(text) {
      return text;
    },

    getType(id) {
      for (let i = 0; i < this.type.length; i++) {
        const element = this.type[i];
        if (element.value === id) {
          return element.text;
        }
      }
    },

    getRisk(risk) {
      if (!risk) {
        return "Não Informado";
      } else if (risk <= 0.12) {
        return "Risco Baixo";
      } else if (risk > 0.12 && risk <= 0.32) {
        return "Risco Médio";
      } else return "Risco Alto";
    },

    getImpact(impact) {
      if (!impact) {
        return "Não Informado";
      } else if (impact == 0.8) {
        return "Gravíssimo";
      } else if (impact == 0.6) {
        return "Grave";
      } else if (impact == 0.4) {
        return "Médio";
      } else if (impact == 0.2) {
        return "Leve";
      } else if (impact == 0.05) {
        return "Sem Impacto";
      }
    },

    getProbability(probability) {
      if (!probability) {
        return "Não Informado";
      } else if (probability == 1) {
        return "Quase certo";
      } else if (probability == 0.8) {
        return "Alta";
      } else if (probability == 0.6) {
        return "Média";
      } else if (probability == 0.4) {
        return "Baixa";
      } else if (probability == 0.2) {
        return "Rara";
      }
    }
  }
};
</script>

<style>
.remove-p p:first-child {
  display: inline;
}

.remove-p p {
  margin: 0px;
}
</style>
