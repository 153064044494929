<template>
  <div>
    <h2 style="font-size: 1.3em;" v-if="list[0] && list[0].type" class="mb-5">
      {{ pos }}. {{ getType(list[0].type) }}
    </h2>
    <div v-for="(subItem, j) in list" :key="j" class="report-detail mb-10">
      <div v-if="subItem.description" class="mb-5">
        {{ pos }}. {{ j + 1 }}.
        <span v-html="removeP(subItem.description)" class="my-5 remove-p"></span>
      </div>
      <!-- Nome do Titular -->
      <p><b>Títular:</b> {{ subItem.datagroupName }}</p>
      <div class="my-5" v-if="subItem.legal">
        <b> Base Legal: </b> {{ getLegal(subItem.legal) }} <br />
      </div>
      <div class="mb-5" v-if="subItem.consent">
        <b> Consenso: </b> {{ subItem.consent }} <br />
      </div>
      <div v-if="subItem.data.length > 0">
        <b>Dados:</b>
        <span v-for="(data, m) in subItem.data" :key="m">
          {{ data.name }}<span v-if="m < subItem.data.length - 1">,</span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: Array,
    pos: { type: Number, default: 1 },
  },
  data: () => ({
    legal: [
      { text: 'Consentimento do titular', value: '1' },
      { text: 'Cumprimento de obrigação legal ou regulatória', value: '2' },
      {
        text: 'Execução, pela administração pública, de políticas públicas',
        value: '3',
      },
      { text: 'Realização de estudos por órgão de pesquisa', value: '4' },
      { text: 'Execução de contrato', value: '5' },
      { text: 'Diligências pré-contratuais', value: '6' },
      { text: 'Exercício regular de direitos', value: '7' },
      { text: 'Proteção da vida e da incolumidade física', value: '8' },
      { text: 'Tutela da saúde', value: '9' },
      { text: 'Legítimo Interesse', value: '10' },
      { text: 'Proteção de crédito', value: '11' },
      { text: 'Prevenção à fraude em sistemas eletrônicos', value: '12' },
      { text: 'Segurança do titular em sistemas eletrônicos', value: '13' },
      { text: 'Não atendida', value: '14' },
    ],
    type: [
      { text: 'Coleta', value: '1' },
      { text: 'Uso/Finalidade', value: '2' },
      { text: 'Compartilhamento', value: '3' },
      { text: 'Retenção/Armazenamento', value: '4' },
      { text: 'Descarte', value: '5' },
      { text: 'Vulnerabilidades', value: '6' },
      { text: 'Plano de Ação', value: '7' },
    ],
  }),
  methods: {
    getType(id) {
      for (let i = 0; i < this.type.length; i++) {
        const element = this.type[i]
        if (element.value === id) {
          return element.text
        }
      }
    },
    getLegal(id) {
      for (let i = 0; i < this.legal.length; i++) {
        const element = this.legal[i]
        if (element.value === id) {
          return element.text
        }
      }
    },
    removeP(text) {
      return text
      // return text.replace(/[<p[A-Za-z]>]/g, '').replace(/[</p>]/g, '')
    },
  },
}
</script>

<style>
/* .report-detail p:first-child {
  display: inline;
} */

.remove-p p:first-child{
display: inline;
}

.remove-p p{
  margin: 0px;
}
</style>
